import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { Box, Card, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react'; // styled components
import { useRouter } from 'next/router';

export const SearchOutlinedIcon = styled(SearchOutlined)(({
	theme
}) => ({
	color: 'white',
	marginRight: 6
}));

export const SearchResultCard = styled(Card)(() => ({
	position: 'absolute',
	top: '100%',
	paddingTop: '0.5rem',
	paddingBottom: '0.5rem',
	width: '100%',
	
	zIndex: 99
}));


const SearchBox = (props) => {
	const [resultList, setResultList] = useState([]);

	const parentRef = useRef();
	const router = useRouter();

	const search = async e => {
		const value = e.target?.value;
		// const facetValues = await factcheckIndex.searchForFacetValues("title", value, { maxFacetHits: 5 });
		// if (!value) setResultList([]); else setResultList(facetValues.facetHits);
	};

	const handleSearch = useCallback(event => {
		event.persist();
		search(event);
	}, []);

	const handleKeyPress = useCallback(event => {
		if (event.key === "Enter") {
			if (event.target.value.length === 0) {
				router.push({
					pathname: '/factchecks',
				});
			} else {
				router.push({
					pathname: '/factchecks',
					query: {
						query: event.target.value
					}
				});
			}

		}
	}, []);


	const styles = {
		root: {                           // - The TextField-root
			border: 'solid 3px #0ff',     // - For demonstration: set the TextField-root border
			padding: '3px',               // - Make the border more distinguishable

			// (Note: space or no space after `&` matters. See SASS "parent selector".)
			'& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
				'& fieldset': {            // - The <fieldset> inside the Input-root
					borderColor: 'pink',   // - Set the Input border
				},
				'&:hover fieldset': {
					borderColor: 'yellow', // - Set the Input border when parent has :hover
				},
				'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
					borderColor: 'green',
				},
			},
		},
	};

	const handleDocumentClick = () => {
		setResultList([]);
	};

	useEffect(() => {
		window.addEventListener('click', handleDocumentClick);
		return () => {
			window.removeEventListener('click', handleDocumentClick);
		};
	}, []);

	return <Box position="relative" flex="1 1 0" minWidth="225px" mx="auto"
		sx={{
			marginRight: '1rem',
		}}
	{...{
		ref: parentRef,
	}}>
		<TextField variant="outlined" placeholder='Zoek een factcheck' fullWidth onChange={handleSearch} onKeyPress={handleKeyPress} InputProps={{
			maxLength: 2,
			sx: {
				height: 44,
				paddingRight: 0,
				color: 'black',
				backgroundColor: '#f0f2ff',
			},
			startAdornment: <SearchOutlinedIcon style={{color: '#171554'}} color='inherit' fontSize="medium" />
		}}
			sx={{
				'& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
					'& fieldset': {            // - The <fieldset> inside the Input-root
						borderColor: 'white',   // - Set the Input border
					},
					'&:hover fieldset': {
						borderColor: '#34A8F7', // - Set the Input border when parent has :hover
					},
					'&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
						borderColor: '#34A8F7',
					},
				},
			}} />
	</Box>;
};

export default SearchBox;

